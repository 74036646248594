<template>
  <div>
    <div class="navbar">
      <img src="@/assets/images/back_black.png" class="navbar_back" @click="toBack" />
      <span class="navbar_title">如何进行采购</span>
      <span></span>
    </div>

    <div class="content">
      <img src="@/assets/images/caigou-cover.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isUniApp: false,
      envLoading: true
    }
  },
  created () {
    this.isUniApp = !!this.$route.query.auth_token
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.envLoading = false
    })
  },
  methods: {
    toBack () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateBack({
            delta: 1
          })
        }
      } else {
        window.history.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
img {
    max-width: 100%;
}
</style>
